<template>
  <div class="">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入标题" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addGroup()">添加</el-button>
      <el-button type="danger" size="small" icon="el-icon-delete" @click="delGroup()">删除</el-button>
    </div>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="id"
          label="ID"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="title"
          label="标题"
          min-width="200">
      </el-table-column>
      <el-table-column
          prop="commission_first"
          label="一级佣金比例"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="commission_second"
          label="二级佣金比例"
          align="center"
          width="200">
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="添加时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editGroup(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog
        title="编辑等级"
        :visible.sync="showModal"
        :destroy-on-close="true"
        width="700px">
      <el-form :model="form" size="small">
        <el-form-item label="等级名称" :label-width="formLabelWidth">
          <el-input v-model="form.title" autocomplete="off" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="一级佣金比例" :label-width="formLabelWidth">
          <el-input v-model="form.commission_first" autocomplete="off" placeholder="请输入比例">
            <template slot="append">% 0~100</template>
          </el-input>
        </el-form-item>
        <el-form-item label="二级佣金比例" :label-width="formLabelWidth">
          <el-input v-model="form.commission_second" autocomplete="off" placeholder="请输入比例">
            <template slot="append">% 0~100</template>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showModal = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveGrade" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      form: {
        id: 0,
        title: '',
        commission_first: '',
        commission_second: '',
      },
      showModal: false,
      formLabelWidth: '120px',
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
      },
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
      }
      this.$api.distribution.guiderGradeIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    addGroup() {
      this.form = {
        id: 0,
        title: '',
        commission_first: '',
        commission_second: '',
      }
      this.showModal = true
    },
    editGroup(item) {
      this.form = item
      this.showModal = true
    },
    saveGrade() {
      if(this.form.title=='') {
        this.fail('名称不能为空');
        return false
      }
      this.$api.distribution.guiderGradeEdit(this.form,res=>{
        if(res.errcode==0) {
          this.showModal = false
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    delGroup() {
      var that = this
      var sel = this.$refs.rfTable.selection
      if (sel.length < 1) {
        that.tips('请选择删除项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确认要删除这些数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.distribution.guiderGradeDel({ids: ids}, function (res) {
          if (res.errcode == 0) {
            that.success('删除成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
