const fieldList = [
    {type:'text', title: '文本框'},
    {type:'number', title: '数字'},
    {type:'email', title: '邮件'},
    {type:'date', title: '日期'},
    {type:'time', title: '时间'},
    {type:'idcard', title: '身份证'},
    {type:'mobile', title: '手机号'}
]

export default {
    fieldList
}
